<template>
  <div class="searchList">
    <div class="banner-size">
      <div class="banner-limit banner-info">
        <span class="banner-fMax">政策匹配</span>
        <span class="banner-fMin banner-other">精准匹配, 送政策、送服务</span>
      </div>
    </div>
    <div class="width-limit search-result">
      <div class="s-container">
        <div class="company-name">{{ company.name }}</div>
        <div class="flex-row company-tips">
          <span class="tip-one" v-if="company.shxydm"
            >社会统一信用代码: {{ company.shxydm }}</span
          ><span class="tip-one" v-if="company.type">{{ company.type || "" }}</span>
        </div>
      </div>
      <div class="flex-row-between check-result">
        <div class="flex-row r-left">
          <div><i class="el-icon-success icon-success"></i></div>
          <div>
            <div class="r-title">
              <span class="r-blue">分析完成! </span> 符合您企业申报的项目共<span
                class="r-red"
                >{{ projectList.length || "0" }}</span
              >个
            </div>
            <div class="r-tip">
              资料越完整，匹配越精准，<a
                class="r-blue border-bottom"
                @click="gotoUserCenter"
                >立即完善企业信息</a
              >（已完善{{ company.percent }}）
            </div>
          </div>
        </div>
        <div class="r-right">
          <div class="r-red">{{ company.money || "--" }}万元</div>
          <div>预计最多可获取的额度</div>
        </div>
      </div>

      <div class="policy-list">
        <div class="flex-row-between p-box">
          <div class="p-title">申报项目</div>
          <div class="p-tip">*本网站所提供的政策匹配结果仅供参考</div>
        </div>
        <ProjectForm class="mt-20" :lists="projectList"></ProjectForm>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectForm from "@/components/projectForm";

export default {
  name: "searchCompany",
  components: { ProjectForm },
  data() {
    return {
      selectEnterprise: null,
      company: {
        name: "",
        shxydm: "",
        type: "",
        money: "--",
        percent: "0%",
      },
      projectList: [],
    };
  },
  mounted() {
    let params = sessionStorage.getItem("searchCompanyData");
    if (params) {
      params = JSON.parse(params);
      this.keyWords = params.keyWords;
      this.selectEnterprise = params.selectEnterprise;
      console.log("params:", params);
      this.company = {
        name: this.keyWords,
        shxydm: this.selectEnterprise.shxydm,
        money: "--",
      };
      this.startSearch();
    }
  },
  methods: {
    startSearch() {
      if (this.selectEnterprise == null || this.selectEnterprise.shxydm == null) {
        this.$message({
          message: "网络异常，请稍后重试",
          type: "error",
        });
        return;
      }
      this.$httpApi
        .get(
          `/dev-api/basicinfo/baseitem/getEnterPermitItem?shxydm=${this.selectEnterprise.shxydm}`
        )
        .then((res) => {
          console.log("getEnterPermitItem:", res);
          if (res.code == 200 && res.data != null) {
            this.company.percent = res.data.percent;
            this.projectList = res.data.items;
            // this.$forceUpdate();
          }
        })
		.catch((err) => {
		  
		});
    },
    gotoUserCenter() {
      this.$router.replace({ path: "/workplace/setting" });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/index.scss";

.searchList {
  .banner-size {
    background: url("../../assets/images/icon_banner_declare.png");
  }
  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .banner-other {
    margin-left: 16px;
  }

  .s-container {
    padding: 20px 0;
    .company-name {
      font-size: 24px;
      color: #000;
      font-weight: 700;
      padding: 30px 20px 10px 0;
    }
    .company-tips {
      justify-content: flex-start;
      .tip-one {
        border: 1px solid #dddddd;
        color: #333;
        font-size: 13px;
        padding: 6px;
        margin-right: 10px;
      }
    }
  }
  .check-result {
    background: #f0fcff;
    padding: 20px;
    border-radius: 10px;

    .r-left {
      .icon-success {
        font-size: 30px;
        color: #4795f1;
        margin-right: 10px;
      }
    }
    .r-right {
      text-align: center;
      font-size: 11px;
      color: #000;
    }
    .r-title {
      font-size: 18px;
      font-weight: 500;
      font-family: PingFangSC-Regular;
      color: #000;
      padding: 10px 0;
    }
    .r-blue {
      color: #4795f1;
    }
    .r-red {
      color: #f00;
      margin: 0 6px;
      font-size: 18px;
    }
    .r-tip {
      font-size: 13px;
      font-weight: 400;
      font-family: PingFangSC-Regular;
      color: #000;
    }
    .border-bottom {
      text-decoration: underline;
    }
  }
  .policy-list {
    margin: 50px 0;
    font-family: PingFangSC-Regular;
    .p-box {
      border-bottom: 3px solid #000;

      .p-title {
        font-size: 18px;
        color: #000;
        font-weight: 600;
      }
      .p-tip {
        font-size: 12px;
        color: #999999;
      }
    }
  }
}
</style>
