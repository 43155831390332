<template>
  <!-- 只要在el-table元素中定义了height属性，即可实现固定表头的表格，而不需要额外的代码。 -->
  <el-table :data="lists" style="width: 100%" :height="tableHeight" align="center" stripe>
    <el-table-column label="序号" width="80" align="center">
      <template slot-scope="scope">
        <div>{{ scope.$index + 1 }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="标题" min-width="400"> </el-table-column>
    <!-- <el-table-column prop="money" label="资金范围" width="120" align="center">
    </el-table-column> -->
    <el-table-column prop="deptName" label="区划" width="120" align="center">
    </el-table-column>
    <!-- <el-table-column prop="remainTime" label="申报日期" width="160" align="center">
      <template>
        <div class="text-red">剩98天</div>
      </template>
    </el-table-column> -->
    <el-table-column label="操作" width="120" align="center">
      <template slot-scope="scope">
        <el-button type="primary" size="small" @click="clickCell(scope)"
          >查看详情</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "publicForm",
  props: {
    lists: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      tableHeight: "300",
    };
  },
  mounted() {
    if (this.lists.length > 10) {
      // 如果数据大于10条则固定头部， 650刚好显示完10条数据左右
      this.tableHeight = "650";
    }
  },
  methods: {
    clickCell(scope) {
      console.log("选择的是:", scope);
      // return;
      this.$store.commit("updateCurrentGuideId", scope.row.itemBaseCode);
      this.$router.push({
        name: "guide",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/com/theme.scss";
/* 标题 */
.el-table__header-wrapper {
  .is-leaf {
    background: $bc-lightGrey;
  }
}
/* 内容 */
.el-table__body-wrapper {
  /* 状态栏 */
  // .el-table_1_column_1 {
  //     color: $color-danger !important;
  // }
  /* 横向属性 */
  .el-table__row {
    height: 60px;
    cursor: pointer;
    &:hover {
      // height: 70px;
      // font-size: $font-size18;
      font-weight: bold;
      .el-table_1_column_2 {
        color: $color-primary !important;
      }
    }
  }
}
.text-red {
  color: $color-danger;
}
</style>
